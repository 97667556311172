<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      width="1300px"
      :ok-button-props="{ props: { loading: submitting } }"
      title="订单明细"
    >
      <template slot="footer">
        <a-button @click="handleCancel">
          关闭
        </a-button>
      </template>
      <a-row>
        <a-col :span="3">
          <a-descriptions
            :column="1"
            class="custom-descriptions"
          >
            <span>产品类型</span>
            <span>销售数量</span>
          </a-descriptions>
        </a-col>
        <a-col :span="3">
          <a-descriptions
            :column="1"
            class="custom-descriptions"
          >
            <span>{{ sumData.product_type_display }}</span>
            <span>{{ sumData.count }}</span>
          </a-descriptions>
        </a-col>
        <a-col :span="3">
          <a-descriptions
            :column="1"
            class="custom-descriptions"
          >
            <span>产品名称</span>
            <span>销售金额(元)</span>
          </a-descriptions>
        </a-col>
        <a-col :span="3">
          <a-descriptions
            :column="1"
            class="custom-descriptions"
          >
            <span>{{ sumData.product_name }}</span>
            <span>{{ sumData.fee }}</span>
          </a-descriptions>
        </a-col>
        <a-col :span="3">
          <a-descriptions
            :column="1"
            class="custom-descriptions"
          >
            <span>产品规格</span>
          </a-descriptions>
        </a-col>
        <a-col :span="3">
          <a-descriptions
            :column="1"
            class="custom-descriptions"
          >
            <span>{{ sumData.product_specification_name }}</span>
          </a-descriptions>
        </a-col>
      </a-row>
      <a-form class="custom-nested-search-form" :form="searchDetailForm" @submit="handleDetailSearch">
        <a-row :gutter="24">
          <a-col :span="6" :xxl="4">
            <a-form-item>
              <a-input
                v-decorator="['order_no', {
                  normalize: this.$lodash.trim
                }]"
                placeholder="请输入订单号"
                allow-clear
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6" :xxl="4">
            <a-form-item label="订单类型">
              <a-select
                v-decorator="['record_type']"
                placeholder="请选择订单类型"
                allow-clear
              >
                <a-select-option
                  v-for="spec in orderTypeOptions"
                  :key="spec.slug"
                  :value="spec.slug"
                >
                  {{ spec.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4" :xxl="4">
            <a-form-item>
              <a-space>
                <a-button type="primary" @click="handleDetailSearch">
                  搜索
                </a-button>
                <a-button @click="handleDetailReset">
                  刷新
                </a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :pagination="false"
        row-key="id"
      />
      <pagination
        v-show="pagination.total_count > 0"
        :total-count="pagination.total_count"
        :page.sync="query.page"
        :per-page.sync="query.per_page"
        @change="fetchData"
      />
    </a-modal>
  </div>
</template>

<script>
import { formatCurrency, formatBigNumber } from '@/utils/filter'
import { findProductSaleStatisticsDetail } from '@/api/product_statistic'
import Pagination from '@/components/Pagination'

export default {
  name: 'SpecificationDetail',
  components: {
    Pagination
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    DateBegin: {
      type: String
    },
    DateEnd: {
      type: String
    },
    sumData: {
      type: Object
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    orderTypeOptions() {
      return [{
        slug: 'service',
        name: '殡仪服务'
      }, {
        slug: 'subscribe',
        name: '附加产品'
      }, {
        slug: 'add_item',
        name: '殡仪服务订单新增项'
      }
      ]
    },
    columns() {
      return [
        {
          title: '关联订单',
          dataIndex: 'order_no',
          width: 80
        },
        {
          title: '订单类型',
          dataIndex: 'order_type_display',
          width: 80
        },
        {
          title: '销售数量',
          dataIndex: 'count',
          width: 80,
          customRender: formatBigNumber
        },
        {
          title: '销售金额(元)',
          dataIndex: 'fee',
          width: 80,
          customRender: formatCurrency
        },
        {
          title: '订单状态',
          dataIndex: 'order_status',
          width: 100
        }
      ]
    }
  },
  data() {
    return {
      searchDetailForm: this.$form.createForm(this, { name: 'search_detail_form' }),
      data: [],
      submitting: false,
      query: {},
      loading: false,
      orderStatusList: [],
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    handleCancel() {
      this.isShow = false
    },
    handleDetailReset(e) {
      this.searchDetailForm.resetFields()
      this.query = {}
      this.fetchData()
    },
    handleDetailSearch(e) {
      e.preventDefault()
      const fieldsValue = this.searchDetailForm.getFieldsValue()
      this.query = {
        ...fieldsValue,
        date_begin: this.DateBegin,
        date_end: this.DateEnd
      }
      this.fetchData()
    },
    fetchData() {
      this.loading = true
      findProductSaleStatisticsDetail(Object.assign({ product_specification_id: this.sumData.specification_id }, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
